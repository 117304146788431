<template>
  <div class="facture-invest">
    <b-button
      variant="success"
      class="config-btn ml-2"
      @click="fixDate"
      v-b-modal.addFactureEnergiInvest
      title="Créer des factures pour Loyer EI - SCI"
    >
      <font-awesome-icon icon="file-medical" class="file-medical" />
    </b-button>
    <b-modal
      id="addFactureEnergiInvest"
      ref="addFactureEnergiInvest"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-invest"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Nouvelle facture</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('addFactureEnergiInvest')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleSubmitNewFacture"
            class="form-modal-custom-style"
          >
            <div class="ModelCol">
              <b-form-group label="Année" label-for="year" class="mr-2">
                <b-form-select
                  id="year"
                  v-model="filterYear"
                  :options="ListYear"
                  class="b-form-select-raduis"
                  @change="handleChangeYearModal"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Mois"
                label-for="month"
                class="input-modal-champ"
              >
                <b-form-select
                  id="month"
                  v-model="filterMonth"
                  :options="computedGetMonthForYearTh"
                  text-field="text"
                  value-field="id"
                  class="b-form-select-raduis"
                  required
                  @change="handleChangeMonthModal"
                >
                </b-form-select>
              </b-form-group>
            </div>
            <b-form-group
              label="Filiale Installateur"
              label-for="page"
              class="input-modal-champ mt-1"
            ></b-form-group>
            <multiselect
              v-model="factureToAdd"
              :options="ComputedListFiliale"
              placeholder="Rechercher une filiale"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              label="name"
              track-by="name"
              :preselect-first="false"
              class="mb-2"
              :loading="loadingSelect"
            >
            </multiselect>

            <div class="box-items-facture" v-if="factureToAdd">
              <div
                class="box-item-facture"
                style="display: grid"
                v-for="(facture, indexFacture) in factureToAdd"
                :key="facture.name"
              >
                <div class="item-facture-title">
                  {{ facture.name }}
                  <p class="worning-rib-style" v-if="!facture.has_rib">
                    Cette sociter n'a pas de rib favorie
                  </p>
                </div>
                <div>
                  <b-row
                    class="row rib_block mb-3 pb-4"
                    v-for="(fac, index) in facture.biens"
                    :key="index"
                  >
                    <!-- Séquences factures  -->
                    <b-col v-if="fac.blocked" col xs="12" sm="8" md="8">
                      <b-form-group
                        label="Nature des locaux *"
                        label-for="bien-facture-format-input"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="bien-facture-format-input"
                          v-model="fac.name"
                          :disabled="fac.blocked"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col col xs="12" sm="3" md="3">
                      <b-form-group
                        v-if="!fac.blocked"
                        label="Nature des locaux *"
                        label-for="bien-facture-format-input"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="bien-facture-format-input"
                          v-model="fac.name"
                          :disabled="fac.blocked"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col col xs="12" sm="2" md="2">
                      <b-form-group
                        v-if="!fac.blocked"
                        label="Locataire *"
                        label-for="bien-facture-format-input"
                        class="input-modal-champ"
                      >
                        <b-form-select
                          id="locataire-select"
                          v-model="fac.locataire_id"
                          :options="getListFilialesLocateur"
                          text-field="text"
                          value-field="value"
                          class="b-form-select-raduis"
                          :disabled="fac.blocked"
                          required
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col col xs="12" sm="2" md="2">
                      <div
                        v-if="!fac.blocked"
                        role="group"
                        class="form-group input-modal-champ"
                      >
                        <label for="swift-template-input" class="d-block"
                          >Intermédiaire *
                          <font-awesome-icon
                            v-if="!fac.has_rib"
                            class="worning-rib-style ml-1"
                            icon="info"
                            :id="'disabled-wrapper' + index"
                          />
                          <b-tooltip
                            :target="'disabled-wrapper' + index"
                            triggers="hover"
                          >
                            Cette sociter n'a pas de rib favorie</b-tooltip
                          >
                        </label>
                        <b-form-select
                          id="locataire-select"
                          v-model="fac.intermediaire_id"
                          :options="getListFilialesIntermidier"
                          text-field="text"
                          value-field="value"
                          class="b-form-select-raduis"
                          :disabled="fac.blocked"
                        ></b-form-select>
                      </div>
                    </b-col>
                    <b-col col xs="12" sm="2" md="2">
                      <div
                        v-if="!fac.blocked"
                        role="group"
                        class="form-group input-modal-champ"
                      >
                        <label for="swift-template-input" class="d-block"
                          >TVA % *
                          <button
                            type="button"
                            v-b-modal.addtvaModal
                            class="ml-2 btn-add-tva-unite"
                          >
                            <font-awesome-icon
                              icon="plus"
                              class="icon-plus-tva-unite"
                            /></button
                        ></label>

                        <b-form-select
                          id="tva"
                          v-model="fac.tva"
                          required
                          :options="getAllTvas"
                          class="b-form-select-raduis "
                          text-field="valeur"
                          value-field="valeur"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="TVA %"
                        ></b-form-select>
                      </div>
                    </b-col>
                    <b-col col xs="12" sm="2" md="2">
                      <b-form-group
                        v-if="!fac.blocked"
                        label="Montant HT *"
                        label-for="montant_bien"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="montant_ht_bien"
                          v-model="fac.montant"
                          required
                          pattern="[0-9-,-.]+"
                          title="This input should be only numbers"
                          type="number"
                          min="0.01"
                          step="0.01"
                          :class="
                            fac.montant
                              ? 'input-background-color-positive'
                              : 'input-background-color-zero'
                          "
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col
                      col
                      xs="12"
                      sm="12"
                      md="12"
                      class="row-intermediaire"
                      v-if="fac.intermediaire_id != null && !fac.blocked"
                    >
                      <b-col col xs="12" sm="7" md="7"></b-col>
                      <b-col col xs="12" sm="2" md="2">
                        <b-form-group
                          label="TVA Intermediaire % *"
                          label-for="swift-template-input"
                          class="input-modal-champ"
                        >
                          <b-form-select
                            id="tva"
                            v-model="fac.tva_intermediaire"
                            required
                            :options="getAllTvas"
                            class="b-form-select-raduis "
                            text-field="valeur"
                            value-field="valeur"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="TVA %"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        col
                        xs="12"
                        sm="2"
                        md="2"
                        v-if="fac.intermediaire_id != null && !fac.blocked"
                      >
                        <b-form-group
                          label="Montant HT Intermediaire *"
                          label-for="montant_bien"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            autocomplete="off"
                            id="montant_ht_intermediaire"
                            v-model="fac.montant_intermediaire"
                            required
                            min="0.01"
                            pattern="[0-9-,-.]+"
                            title="This input should be only numbers"
                            type="number"
                            step="0.01"
                            :class="
                              fac.montant_intermediaire
                                ? 'input-background-color-positive'
                                : 'input-background-color-zero'
                            "
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-col>
                    <b-col v-if="!fac.blocked" col xs="12" sm="6" md="6">
                      <b-form-group
                        label="Adresse *"
                        label-for="bien-facture-format-input"
                        class="input-modal-champ"
                      >
                        <b-form-textarea
                          autocomplete="off"
                          id="bien-facture-format-input"
                          v-model="fac.address"
                          type="text"
                          required
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="!fac.blocked" col xs="12" sm="6" md="6">
                      <b-form-group
                        label="Description"
                        label-for="bien-facture-format-input"
                        class="input-modal-champ"
                      >
                        <b-form-textarea
                          id="description_bien"
                          v-model="fac.description"
                          autocomplete="off"
                        >
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>

                    <div class="edit-action-biens">
                      <div class="action-icon" v-if="fac.blocked">
                        <font-awesome-icon
                          title="Déblocker rib"
                          icon="eye-slash"
                          class="fa-2x bell1 m-2"
                          @click="
                            BlockUnblockBienFilialeClick(
                              index,
                              fac,
                              indexFacture
                            )
                          "
                        />
                      </div>
                      <div class="action-icon" v-if="!fac.blocked">
                        <font-awesome-icon
                          title="Blocker rib"
                          icon="eye"
                          class="fa-2x bell1 m-2"
                          @click="
                            BlockUnblockBienFilialeClick(
                              index,
                              fac,
                              indexFacture
                            )
                          "
                        />
                      </div>
                    </div>
                    <hr />
                  </b-row>
                </div>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>

    <b-modal
      ref="addtvaModal"
      id="addtvaModal"
      no-close-on-backdrop
      :hide-footer="true"
      @hidden="resteTvaUniteModal"
      title="Ajouter tva"
      modal-class="cutsom-modal-bootstrap"
      size="sm"
    >
      <b-form-group label="Tva" label-for="unite" class="input-modal-champ">
        <b-form-input
          id="tva"
          v-model="valeur"
          required
          type="number"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>
      <div class="error-message d-flex justify-content-center">
        <div class="error">
          <div>{{ errorTvaUnite }}</div>
        </div>
      </div>
      <div class="form-modal-custom-style mt-2">
        <div class="actionModel">
          <b-button class="button-valide-style" @click="addTav('tva')">
            <span>
              Valider
              <div v-if="loadingTvaUnite" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  props: {
    month: { required: true },
    year: { required: true }
  },
  data() {
    return {
      factureToAdd: [],
      error: null,
      loading: false,
      filterMonth: this.month,
      filterYear: this.year,
      ListYear: [],
      loadingSelect: false,
      errorTvaUnite: null,
      valeur: null,
      loadingTvaUnite: false,
      listTva: []
    };
  },
  computed: {
    ...mapGetters([
      'getSciFiliales',
      'getMonthForYearTh',
      'listeFiliales',
      'getAllTvas',
      'getLastMonthBilles',
      'getListFilialesLocateur',
      'getListFilialesIntermidier'
    ]),
    ComputedListFiliale() {
      return this.getSciFiliales.map(item => {
        return {
          name: item.name,
          id: item.id,
          montant: 0,
          biens: item.biens,
          has_rib: item.has_rib
        };
      });
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name,
        id: item.month
      }));
      return tab;
    }
  },

  methods: {
    ...mapActions([
      'getAllFilialeSci',
      'addNewFactureEnergieInvestActions',
      'fetchMonthForYearTH',
      'generateFactureEnergiInvest',
      'getSettingFilialeTh',
      'blockunblockBienFiliale',
      'getTvas',
      'addUniteTva',
      'fetchBillesOfLastMonth',
      'getAllFilialesListe'
    ]),
    async BlockUnblockBienFilialeClick(index, fac, indexFacture) {
      const response = await this.blockunblockBienFiliale({
        id: fac.id,
        blocked: fac.blocked ? false : true
      });
      if (response.succes) {
        this.factureToAdd[indexFacture].biens[index].blocked =
          response.response.blocked;
      }
    },
    resetModal() {
      this.factureToAdd = [];
      this.error = null;
      this.loading = false;
      this.ListYear = [];
      this.filterYear = null;
      this.filterMonth = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleChangeYearModal() {
      this.factureToAdd = [];
      this.filterMonth = null;
      this.fetchMonthForYearTH(this.filterYear);
    },
    async handleChangeMonthModal() {
      const response = await this.fetchBillesOfLastMonth({
        mois: this.filterMonth,
        annee: this.filterYear
      });
      this.factureToAdd = [];
      response.response.map(item => {
        this.factureToAdd.push({
          id: item.filiale,
          name: item.filiale_name,
          has_rib: item.has_rib,
          biens: item.biens.map(i => {
            return {
              id: i.id,
              name: i.bien_name,
              locataire_id: i.locataire_id,
              intermediaire_id: i.intermediaire_id,
              tva: i.tva,
              tva_intermediaire: i.tva_intermediaire,
              montant: i.HT,
              montant_intermediaire: i.HT_intermediaire,
              description: i.description,
              address: i.adresse,
              has_rib: i.has_rib,
              blocked: i.blocked
            };
          })
        });
      });
    },
    fixDate() {
      var courantYear = moment().year();
      this.filterMonth = this.month;
      this.filterYear = this.year;
      for (var i = 2019; i <= courantYear; i++) {
        this.ListYear.push({ value: i, text: i });
      }
      if (this.filterMonth != null) {
        this.handleChangeMonthModal();
      }

      this.getAllFilialeSci();
    },
    async handleSubmitNewFacture() {
      if (this.factureToAdd.length) {
        this.loading = true;
        var params = new FormData();
        params.append('year', this.filterYear);
        params.append('month', this.filterMonth);
        for (let i = 0; i < this.factureToAdd.length; i++) {
          params.append('scis[' + i + '][id]', this.factureToAdd[i].id);
          for (let j = 0; j < this.factureToAdd[i].biens.length; j++) {
            params.append(
              'scis[' + i + '][biens][' + j + '][id]',
              this.factureToAdd[i].biens[j].id
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][blocked]',
              this.factureToAdd[i].biens[j].blocked ? 1 : 0
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][name]',
              this.factureToAdd[i].biens[j].name
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][address]',
              this.factureToAdd[i].biens[j].address
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][description]',
              this.factureToAdd[i].biens[j].description
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][locataire_id]',
              this.factureToAdd[i].biens[j].locataire_id
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][intermediaire_id]',
              this.factureToAdd[i].biens[j].intermediaire_id
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][tva]',
              this.factureToAdd[i].biens[j].tva
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][tva_intermediaire]',
              this.factureToAdd[i].biens[j].tva_intermediaire
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][montant_intermediaire]',
              this.factureToAdd[i].biens[j].montant_intermediaire
            );
            params.append(
              'scis[' + i + '][biens][' + j + '][montant]',
              this.factureToAdd[i].biens[j].montant
            );
          }
        }
        const response = await this.addNewFactureEnergieInvestActions(params);
        if (response.succes) {
          let params = {
            year: this.filterYear,
            month: this.filterMonth
          };
          this.hideModal('addFactureEnergiInvest');
          let errorMessage =
            response.message.reworked && response.message.reworked > 0
              ? '\n' +
                '<div>Factures modifer : ' +
                response.message.reworked +
                ' <span style="font-size: 18px;border-radius: 50%;background-color: #ff0000d9;padding: 1px 7px;color: #fff;" title="Total zéro">!</span></div>'
              : '';
          var errorMessages = '';
          let errors = response.message.errors;
          if (errors.length > 0) {
            errors.forEach(function(item) {
              errorMessages +=
                ' <span style="font-size: 15px;background-color: #ff0000d9;padding: 1px 7px;color: #fff;" title="Total zéro">- ' +
                item +
                '</span></div>' +
                '\n';
            });
          }

          let text =
            '<div style="dispaly:flex"><div>Factures ajouté avec succès  : ' +
            response.message.success +
            '\n' +
            '\n' +
            '</div><div>Existe déja : ' +
            response.message.exist +
            '</div>' +
            '\n';

          if (errors.length > 0) {
            text += '<div>Erreurs : ' + '\n' + errorMessages;
          }

          this.$alert('', text, 'success').then(() => {
            this.$emit('reloadData', params);
          });
        } else {
          this.error = response.error;
          this.loading = false;
        }
      } else {
        this.error = 'Filiale required';
      }
    },
    async DeleteLineBienFiliale(index, filiale) {
      filiale.biens.splice(index, 1);
    },
    resteTvaUniteModal() {
      this.valeur = null;
      this.errorTvaUnite = null;
    },
    async addTav(type) {
      if (this.valeur > 100) {
        this.errorTvaUnite = 'tva doit etre inferieur à 100';
      } else if (this.valeur === '' || this.valeur === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: this.valeur,
          type: type
        });
        if (response.success) {
          this.$refs['addtvaModal'].hide();
          this.listTva.push(response.response.valeur);
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addtvaModal'].hide();
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        }
      }
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  },
  async mounted() {
    await this.getSettingFilialeTh();
    this.fetchMonthForYearTH(this.filterYear);

    await this.getTvas();
    this.listTva = this.getAllTvas;
    const response = await this.getAllFilialesListe();
  }
};
</script>

<style lang="scss" scoped>
.facture-invest {
  .file-medical {
    font-size: 13px;
  }
}
.input-background-color-positive {
  background-color: #c1ffec;
}
.input-background-color-zero {
  background-color: #f6b9d2;
}
.box-items-facture {
  .box-item-facture {
    padding: 5px;
    border: 1px solid #e8e6f2;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
    color: black;
    justify-content: space-between;

    .item-facture-title {
      align-self: center;
      text-align: start;
      font-size: 14px;
      margin-bottom: 7px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
    }

    .icon {
      align-self: center;
    }
  }
}
.worning-rib-style {
  color: red;
  font-size: 10px;
}
.rib_block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 4px;
  background-color: #f5f4fa;
  width: 99%;
  border-radius: 4px;
  margin: 0px auto;

  .button-add-rib {
    display: flex;
  }

  .edit-action {
    width: 5%;
    display: flex;
    font-size: 7px;
    justify-content: space-between;
    height: 80px;
    align-content: center;
    align-items: center;
    margin-right: 2%;

    .action-icon {
      cursor: pointer;
    }

    .validate {
      color: green;
    }

    .cancel {
      color: #ab2525;
    }
  }

  .line-rib {
    display: flex;
    justify-content: space-between;
  }
}

.rib_block .edit-action-biens {
  width: 3%;
  display: flex;
  font-size: 7px;
  justify-content: space-around;
  height: 80px;
  align-content: center;
  align-items: center;
  margin-right: 2%;
  position: absolute;
  right: -1%;
  top: -10%;

  .validate {
    color: green;
  }

  .cancel {
    color: #ab2525;
  }
}

.cutsom-modal-bootstrap-EI-th .modal-dialog {
  top: 0%;
  max-width: 70%;
}

.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}

.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}

.font-tabel-produit {
  font-size: 14px;
}
</style>
<style lang="scss">
.modal-facture {
  .modal-dialog {
    max-width: 550px !important;
    margin: auto !important;
  }
}

.multiselect__tag {
  background: #4d4bac;
}

.multiselect__tag-icon:after {
  color: #ffffff;
}

.box-items-facture {
  .box-item-facture {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #4d4bac !important;
      background-color: #4d4bac !important;
    }
  }
}

.cutsom-modal-bootstrap-invest .modal-dialog {
  top: 2%;
  max-width: 90%;
  margin: auto;
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-invest .modal-dialog {
    top: 2%;
    max-width: 80%;
    margin: auto;
  }
}
.row-intermediaire {
  display: flex;
}
</style>
